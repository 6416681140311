<template>
  <div class="dairy-wrapper">
    <p-header></p-header>
    <div class="dairy-container">
      <img src="~common/images/case_bg.png" width="100%" alt="" >
      <div class="content-title">包装供应链管理的<font style="color:#08BB57">实用工具箱</font></div>
      <div class="content-box">
        <div class="content-box-item">
          <div class="title">迅捷版</div>
          <div class="subtitle">适合小微团队，快速完成文件<br/>流转与稿件管理工作</div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">解锁功能：</div>
          </div>
          <div class="subtitle-name-subtitle">稿件对比</div>
          <div class="subtitle-name-subtitle">稿件管理</div>
          <div class="subtitle-name-subtitle">迅捷业务流转</div>

          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">用户数量：10个</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">存储空间：10GB</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">服务支持：5×8小时在线客服</div>
          </div>
          
          <img src="~common/images/case_btn_green.png" @click="goDetail"  class="ic_btn"/>
        </div>
        <div class="content-box-item">
          <div class="title">企业版</div>
          <div class="subtitle">适合对业务管理、数据保护有<br/>更高需求的企业</div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">解锁全部功能：</div>
          </div>
          <div class="subtitle-name-subtitle">专业稿件对比</div>
          <div class="subtitle-name-subtitle">批量稿件管理</div>
          <div class="subtitle-name-subtitle">定制化业务流转</div>
          <div class="subtitle-name-subtitle">更多个性化设置</div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">用户数量：10个</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">存储空间：100GB</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">产品使用培训</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_green.png"  class="ic_right"/>
            <div class="subtitle-name-title">服务支持：5×8小时在线客服</div>
          </div>
          <img src="~common/images/case_btn_green.png" @click="goDetail"  class="ic_btn"/>
        </div>
        <div class="content-box-item">
          <div class="title">企业内网部署版</div>
          <div class="subtitle">适合对数据私密性和企业自有<br/>系统集成有更高要求的企业</div>

          <div class="subtitle-name">
            <img src="~common/images/ic_right_blue.png"  class="ic_right"/>
            <div class="subtitle-name-title">解锁全部功能：</div>
          </div>
          <div class="subtitle-name-subtitle">专业稿件对比</div>
          <div class="subtitle-name-subtitle">批量稿件管理</div>
          <div class="subtitle-name-subtitle">定制化业务流转</div>
          <div class="subtitle-name-subtitle">更多个性化设置</div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_blue.png"  class="ic_right"/>
            <div class="subtitle-name-title">企业自有服务器部署</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_blue.png"  class="ic_right"/>
            <div class="subtitle-name-title">完备的开放接口</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_blue.png"  class="ic_right"/>
            <div class="subtitle-name-title">系统内部系统集成</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_blue.png"  class="ic_right"/>
            <div class="subtitle-name-title">专属培训和技术支持</div>
          </div>
          <div class="subtitle-name">
            <img src="~common/images/ic_right_blue.png"  class="ic_right"/>
            <div class="subtitle-name-title">服务支持：5×8小时在线客服</div>
          </div>
          <img src="~common/images/case_btn_blue.png" @click="goDetail" class="ic_btn"/>
        </div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'

  export default {
    data() {
      return {}
    },
    created() {
    },
    activated() {
    },
    methods: {
      goDetail() {
        this.$router.push({
          name: 'service'
        })
      }
    },
    watch: {},
    components: {
      PHeader,
      PFooter
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .dairy-wrapper
    width 100%
    min-width 1270px
    .dairy-container
      width 100%
      position relative
      box-sizing border-box
      .content-title
        position absolute
        left: 50%;
        transform: translateX(-50%)
        font-size: 0.3rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.49rem;
        text-align: center
        top: 0.88rem;
      .content-box
        position absolute
        top: 1.8rem;
        padding: 0 1.2rem;
        left: 0;
        right: 0;
        display: flex
        .content-box-item
          position relative
          background: #FFFFFF;
          margin-right: 0.11rem
          flex: 1;
          height: 5.3rem
          border-radius: 15px;
          box-shadow: 0rem 0.03rem 0.07rem 0rem rgba(0,0,0,0.2600);
          .title
            font-size: 0.23rem;
            font-weight: bold;
            text-align: center;
            margin-top: 0.2rem
            color: #000000;
            line-height: 0.49rem;
          .ic_btn
            width 2.1rem
            position absolute
            bottom: 0.26rem
            left: 50%;
            transform: translateX(-50%)
          .subtitle
            font-size: 0.14rem;
            font-weight: 400;
            text-align: center;
            letter-spacing: 2px
            color: #000000;
            line-height: 0.23rem;
            padding-bottom: 0.22rem
            border-bottom: 0.02rem solid #E8E8E8
          .subtitle-name
            display flex
            letter-spacing: 1px
            padding: 0.1rem 0 0 0.3rem
            .ic_right
              width: 0.19rem;
              height: 0.19rem;
              margin-right: 0.06rem
            .subtitle-name-title
              display: inline;
              line-height: 0.19rem;
              color: #666666;
              font-size: 0.13rem;
          .subtitle-name-subtitle
            display: block
            padding-left: 0.7rem
            line-height: 0.24rem;
            color: #666666;
            font-size: 0.11rem;
</style>